/* eslint-disable @typescript-eslint/naming-convention */

import { THorizontalAlignValue, TVerticalAlignValue } from "@components/ResourceView/components/constants";

import { IBlog, IImage } from "../types";

export enum PageType {
  LEGAL = "legal",
  VEHICLES_LANDING = "landing",
  STANDALONE = "standalone",
  VEHICLE_SUPPORTED_APP_LIST = "supported",
  VEHICLE_OCA = "single", // OCA - One Click app
  FEATURES_LANDING = "features_landing",
  FEATURES_LIST = "features_list",
  FEATURE = "feature",
  PLANS_LANDING = "plans_landing",
  PLANS_LIST = "plans_list",
  COMPARE_LANDING = "compare_landing",
  BLOG_LANDING = "blog_landing",
  BLOG_CATEGORY = "blog_category",
  HOMEPAGE = "homepage",
  BLOG_POST = "blog_post",
  PRODUCTS = "products",
  REFERRALS = "referrals",
  CUSTOMIZATIONS_LANDING = "customizations_landing",
  CUSTOMIZATIONS_SUPPORTED_APP_LIST = "customization_supported",
  CUSTOMIZATIONS_OCA = "customization_single",
}

export enum ResourceCode {
  OMNISEND = "omnisend_subscribe",
  REFERRAL_PRODUCTS = "referral-products",
  REFERRAL_TOP_BLOCK = "referral-top-block",
  CPA = "car-purchase-assistant-form",
  CPA_R = "car-purchase-assistant-result",
}

export enum ResourceType {
  PAGE = "page",
  SECTION = "section",
  BLOCK = "block",
}

export interface IResource {
  resourceType: ResourceType;
  code?: ResourceCode;
}

export type TDesktopBlockImagePosition = "left" | "right" | "top";
export type TMobileBlockImagePosition = "top" | "bottom";

export interface IBlock extends IResource {
  width: number;
  html: string;
  rawHtml: string;
  link: string;
  name: string;
  image?: IImage;
  backgroundColor?: string;
  roundedBlockBorder?: boolean;
  blockTextAreaPadding?: string;
  roundedBlockImageBorder?: boolean;
  desktopBlockImagePosition?: TDesktopBlockImagePosition;
  mobileBlockImagePosition?: TMobileBlockImagePosition;
  verticalAlign?: TVerticalAlignValue;
  centerTextContentForMobile?: boolean;
  removeBlockSidePadding?: boolean;
  removeBlockSidePaddingMobile?: boolean;
  minHeight?: number;
  blockType?: string;
  relatedProduct?: any;
  hasImageCollage?: boolean;
}
export interface ISectionView {
  color: string;
  imageRepeat: boolean;
  image: IImage;
  blocks: IBlock[];
  sectionFullWidth: boolean;
  contentFullWidth: boolean;
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;
  mobilePaddingTop?: number;
  mobilePaddingRight?: number;
  mobilePaddingBottom?: number;
  mobilePaddingLeft?: number;
  backgroundImageHeight: number;
  mobileBackgroundImageHeight?: number;
  minHeight?: number;
  mobileMinHeight?: number;
  horizontalBlocksAlignment?: THorizontalAlignValue;
  heroBlock?: boolean;
  hideImageOnMobile?: boolean;
  mobileBgColor?: string;
  roundedSectionBorder?: boolean;
}

export interface IDesktopSectionView extends ISectionView {
  reverseBlocksMobile: boolean;
}

export interface ISection extends IResource {
  desktop: IDesktopSectionView;
  mobile: ISectionView;
  type?: string;
}

export interface IPage extends IResource {
  status_code?: number;
  resourceCategory: PageType;
  name: string;
  slug: string;
  title: string;
  breadcrumb: string;
  nameWhenLinked: string;
  descriptionWhenLinked: string;
  metaKeywords: string;
  metaDescription: string;
  image: string;
  sections: ISection[];
  blog?: IBlog;
  noIndex?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
}
